const XLSX = require("xlsx");
// GENERATE SAL
exports.processSALDates = (orders) => {
  return orders.map((order) => {
    let dateOrder = new Date(order.date);
    let [orderDay, orderMonth, orderYear] = [
      dateOrder.getDate(),
      dateOrder.getMonth(),
      dateOrder.getYear() + 1900,
    ];
    let lastMondayOfMonth = lastMondaybeforeFridayOfMonth(
      orderYear,
      orderMonth,
    );
    let nextMonth = orderDay >= lastMondayOfMonth.getDate();
    orderYear = nextMonth && orderMonth == 11 ? orderYear + 1 : orderYear;
    orderMonth =
      nextMonth && orderMonth !== 11
        ? orderMonth + 1
        : nextMonth && orderMonth == 11
          ? 0
          : orderMonth;
    let countryMultiplier =
      order.Country == "US" ||
      order.Country == "United States" ||
      order.countrycode == "US" ||
      order.countrycode == "United States"
        ? 1
        : 2;
    let numbercode = orderMonth * 4 + countryMultiplier;
    let header = `SAL${orderYear.toString().slice(-2)}/${(
      "000" + numbercode
    ).slice(-4)}`;
    return { ...order, header };
  });
};

exports.processSALDatesCountry = (orders, country) => {
  console.log(country);
  return orders.map((order) => {
    let dateOrder = new Date(order.date);
    let [orderDay, orderMonth, orderYear] = [
      dateOrder.getDate(),
      dateOrder.getMonth(),
      dateOrder.getYear() + 1900,
    ];
    let lastMondayOfMonth = lastMondaybeforeFridayOfMonth(
      orderYear,
      orderMonth,
    );
    let nextMonth = orderDay >= lastMondayOfMonth.getDate();
    orderYear = nextMonth && orderMonth == 11 ? orderYear + 1 : orderYear;
    orderMonth =
      nextMonth && orderMonth !== 11
        ? orderMonth + 1
        : nextMonth && orderMonth == 11
          ? 0
          : orderMonth;
    let countryMultiplier = country == "US" ? 1 : 2;

    console.log(countryMultiplier);
    let numbercode = orderMonth * 4 + countryMultiplier;
    let header = `SAL${orderYear.toString().slice(-2)}/${(
      "000" + numbercode
    ).slice(-4)}`;
    return { ...order, header };
  });
};

function lastFridayOfMonth(year, month) {
  var lastDay = new Date(year, month + 1, 0);
  if (lastDay.getDay() < 5) {
    lastDay.setDate(lastDay.getDate() - 7);
  }
  lastDay.setDate(lastDay.getDate() - (lastDay.getDay() - 5));
  return lastDay;
}
function lastMondaybeforeFridayOfMonth(year, month) {
  var day = lastFridayOfMonth(year, month);
  day.setDate(day.getDate() - 4);
  return day;
}
function lastSundaybeforeFridayOfMonth(year, month) {
  var day = lastFridayOfMonth(year, month);
  day.setDate(day.getDate() - 5);
  return day;
}

// GENERATE SAL
exports.generateSAL = (date, country) => {
  let dateOrder = new Date(date);
  let [orderDay, orderMonth, orderYear] = [
    dateOrder.getDate(),
    dateOrder.getMonth(),
    dateOrder.getYear() + 1900,
  ];
  let lastMondayOfMonth = lastMondaybeforeFridayOfMonth(orderYear, orderMonth);
  let nextMonth = orderDay >= lastMondayOfMonth.getDate();
  orderYear = nextMonth && orderMonth == 11 ? orderYear + 1 : orderYear;
  orderMonth =
    nextMonth && orderMonth !== 11
      ? orderMonth + 1
      : nextMonth && orderMonth == 11
        ? 0
        : orderMonth;
  let countryMultiplier = country == "US" || country == "United States" ? 1 : 2;

  let numbercode = orderMonth * 4 + countryMultiplier;

  let header = `SAL${orderYear.toString().slice(-2)}/${(
    "000" + numbercode
  ).slice(-4)}`;

  this.generateSALReverse(header);
  return header;
};

exports.exportTableAll = (data) => {
  console.log(data);
  let d = new Date();
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(data);

  let filename = `trans_his_${da}-${mo}-${ye}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

  XLSX.utils.book_append_sheet(wb, ws, filename);

  /* Trigger Download with `writeFile` */
  XLSX.writeFile(wb, filename + ".xlsx", { compression: true });
};

exports.generateSALReverse = (salref) => {
  let arr = salref.split("/");
  const year = 2000 + parseInt(arr[0].slice(3));
  const month = (arr[1] - 1) / 4;

  const monday = lastMondaybeforeFridayOfMonth(year, month - 1);

  const sunday = lastSundaybeforeFridayOfMonth(year, month);

  return [monday, sunday];
};

exports.filterData = function (filters, dataItems, dataCopy) {
  let arr = [];
  // console.log(filters);
  // if (this.filterOption === "and") {

  if (false) {
    this.filters.map(({ condition, field, value }) => {
      if (value.length) {
        switch (condition) {
          case "contains":
            dataItems = dataItems.filter((x) => {
              let dataValue = x[field].toLowerCase();
              return dataValue.includes(value.toLowerCase());
            });

            break;
          case "does_not_contain":
            dataItems = dataItems.filter((x) => {
              let dataValue = x[field].toLowerCase();

              return !dataValue.includes(value.toLowerCase());
            });

            break;

          case "is":
            dataItems = dataItems.filter((x) => {
              let dataValue = x[field].toLowerCase();

              return dataValue === value.toLowerCase();
            });

            break;
          case "is_not":
            dataItems = dataItems.filter((x) => {
              let dataValue = x[field].toLowerCase();

              return dataValue !== value.toLowerCase();
            });

            break;
        }
      }
    });
  } else {
    filters.map(({ condition, field, value }) => {
      if (value.length) {
        switch (condition) {
          case "contains":
            dataCopy.forEach((x) => {
              let dataValue = `${x[field]}`.toLowerCase();

              if (dataValue.includes(`${value}`.toLowerCase())) {
                arr.push(x);
              }
            });

            break;

          case "does_not_contain":
            dataCopy.forEach((x) => {
              let dataValue = `${x[field]}`.toLowerCase();
              if (!dataValue.includes(`${value}`.toLowerCase())) {
                arr.push(x);
              }
            });
            break;
          case "is":
            dataCopy.forEach((x) => {
              let dataValue = `${x[field]}`.toLowerCase();
              if (dataValue === `${value}`.toLowerCase()) {
                arr.push(x);
              }
            });
            break;
          case "is_not":
            dataCopy.forEach((x) => {
              let dataValue = `${x[field]}`.toLowerCase();
              if (dataValue !== `${value}`.toLowerCase()) {
                arr.push(x);
              }
            });
            break;
        }
      }
    });
    return arr;
  }
  // this.setAllOrders(this.dataItems);

  //   this.$emit("dataFiltered", this.dataFiltered);
};
