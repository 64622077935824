<template>
  <div class="wrapper">
    <slot></slot>

    <slot name="panel" class=""></slot>

    <v-container fluid class="pa-0 table-layout-container">
      <slot name="table"></slot>
    </v-container>
    <slot name="table_pagination"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(3, 3fr); */
  gap: 10px;
  /* grid-template-rows: 500px; */
  /* grid-template-rows: repeat(3, 3fr); */
  grid-template-rows: min-content max-content min-content;
  grid-template-columns: 100%;
  /* max-height: calc(100% - 88px); */
  /* overflow-y: auto; */
  height: 100%;
  margin: auto;
  /* grid-template-columns: 100%; */
}
.w-100 {
  width: 100%;
}
</style>
