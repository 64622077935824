import axios from "axios";
import {
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
} from "../indexv2";

const get_inventory_warnings = async () => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/inventory/warnings`,
      headers: { authorization: token },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export { get_inventory_warnings };
