export const header = [
  { text: "Full Name", value: "FullName" },
  { text: "Point Code", value: "PointCode" },
  { text: "Pool Location", value: "PoolLocation" },
  { text: "Terminates At Depot", value: "TerminatesAtDepot" },
  { text: "Passthru All", value: "PassthroughAll" },
  { text: "Effective From", value: "EffectiveFrom" },
  { text: "Effective To", value: "EffectiveTo" },
  { text: "", value: "Action" },
];

export const depotTerminationData = () => {
  let items = [];
  for (let index = 0; index < 49; index++) {
    items.push({
      id: index + 1,
      FullName: "BNSF-CORWITH",
      PointCode: `USCHI`,
      PoolLocation: "USCHIRBNC",
      TerminatesAtDepot: "USCHIDILT",
      PassthroughAll: "N",
      EffectiveFrom: "",
      EffectiveTo: "",
    });
  }
  return items;
};

export const depotTerminationFormDataRows = [
  {
    title: "Depot Termination",
    value: "depotTermination",
    fields: [
      {
        title: "Full Name",
        value: "FullName",
        rules: "",
        type: "Text",
      },
      {
        title: "Point Code",
        value: "PointCode",
        rules: "required",
        type: "Text",
      },
      {
        title: "Pool Location",
        value: "PoolLocation",
        rules: "",
        type: "Text",
      },
      {
        title: "Terminates At Depot",
        value: "TerminatesAtDepot",
        rules: "required",
        type: "Text",
      },
      {
        title: "Passthru All",
        value: "PassthroughAll",
        type: "Check",
      },
      {
        title: "Effective From",
        value: "EffectiveFrom",
        type: "DatePicker",
      },
      {
        title: "Effective To",
        value: "EffectiveTo",
        type: "DatePicker",
      },
    ],
  },
];
