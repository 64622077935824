import { DateTime } from "luxon";

export const DATES = [
  "DateCreated",
  "DateAllocated",
  "DateAvailable",
  "LastModifiedDate",
  "DateSold",
  "OutDate",
  "EstDateOfReturn",
  "ExpiredDate",
  "EstDateOfReturn",
  "InboundStatusDate",
  "flaggedDateCreated",
  "flaggedDateUpdated",
];

export const ENABLE_DATES = [
  "DateAvailable",
  "EstDateOfReturn",
  "InboundStatusDate",
];

export const parseDate = (date) => {
  return DateTime.fromISO(date).toISODate();
};
export const parseDateZone = (date, zone) => {
  return DateTime.fromISO(date).setZone(zone).toISO({ includeOffset: false });
};

export const parseDateFromFormat = (date, formFormat, toFormat) => {
  return DateTime.fromFormat(date, formFormat).toFormat(toFormat);
};
export const parseDateToISO = (date, formFormat) => {
  return DateTime.fromFormat(date, formFormat).toISO({ includeOffset: false });
};
export const parseFromIsoToFormat = (date, toFormat) => {
  return DateTime.fromISO(date).toFormat(toFormat);
};
export const parseFromIsoToFormatZone = (date, toFormat, zone) => {
  return DateTime.fromISO(date).setZone(zone).toFormat(toFormat);
};
export const parseFromFormat = (date, format) => {
  // console.log(date);
  // console.log(format);
  return DateTime.fromFormat(date, format);
};

export const FromFormatToTimeStamp = (date, format) => {
  return DateTime.fromFormat(date, format).toMillis();
};

export const FromFormatToTimeStampServer = (date, format) => {
  return DateTime.fromFormat(date, format, {
    zone: "America/Phoenix",
  }).toMillis();
};

export const fromISOToUtc = (date) => {
  if (date && date.length) {
    return DateTime.fromISO(new Date(date).toISOString().replace("Z", ""), {
      zone: "America/Phoenix",
    });
  } else {
    return "";
  }
};

export const serverToLocalTime = (date) => {
  return DateTime.fromISO(new Date(date).toISOString().replace("Z", ""), {
    zone: "America/Phoenix",
  })
    .toLocal()
    .toFormat("yyyy-LL-dd HH:mm:ss");
};

export const localToServerTime = (date) => {
  return DateTime.fromSQL(date)
    .setZone("America/Phoenix")
    .toISO({ includeOffset: false });
};

export const today = () => {
  return DateTime.now()
    .toLocal()
    .toISO({ includeOffset: false })
    .replace("Z", "");
};
export const todayFormat = () => {
  return DateTime.now().toLocal().toFormat("yyyy-LL-dd HH:mm:ss");
  // return DateTime.now()
  //   .toLocal()
  //   .toISO({ includeOffset: false })
  //   .replace("Z", "");
};
