import axios from "axios";
import {
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
} from "../indexv2";

const getListOfCustomFieldsForOrganization = (organization_id) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/customfields?organization_id=${organization_id}`,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err));
  });
const createCustomFieldForOrganization = (data) =>
  new Promise((resolve, reject) => {
    console.log("en el endpoint", data);
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/customfields?organization_id=${data.organization_id}`,
      headers: { authorization: token },
      data,
    })
      .then((res) => {
        console.log("response endpooint", res);
        return resolve(res);
      })
      .catch((err) => {
        console.log("error endpooint", err);
        reject(err);
      });
  });
const deleteCustomField = (id) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "DELETE",
      url: `${apiBaseUrl}/customfields?id=${id}`,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err));
  });

const getCustomFieldByOrder = async (docNo) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/customfields`,
      headers: { authorization: token },
      params: {
        docNo,
      },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const update_custom_field_value = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/customfields`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const create_custom_field_by_order = (data) =>
  new Promise((resolve, reject) => {
    let { fieldName, fieldType, fieldValue, docNo } = data;
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/customfields`,
      headers: { authorization: token },
      data: {
        fieldName,
        fieldType,
        fieldValue,
        docNo,
      },
    })
      .then((res) => {
        console.log("response endpooint", res);
        return resolve(res);
      })
      .catch((err) => {
        console.log("error endpooint", err);
        reject(err);
      });
  });
export {
  getListOfCustomFieldsForOrganization,
  createCustomFieldForOrganization,
  deleteCustomField,
  getCustomFieldByOrder,
  update_custom_field_value,
  create_custom_field_by_order,
};
