import axios from "axios";
import { apiBaseUrl } from "../indexv2";

const get_list = async () => {
  const token = localStorage.getItem("access_token");
  return await axios({
    method: "GET",
    url: `${apiBaseUrl}/report/auctioninvites`,
    headers: { authorization: token },
  });
};

export { get_list };
