export const inventory2 = () => {
  let items = [];
  for (let index = 0; index < 49; index++) {
    items.push({
      id: index.toString(),
      EquipmentID: index.toString(),
      Size: "20FT",
      Type: "DRY STD",
      Cndtn: "D&DTIGHT",
      Country: "US",
      State: " IL",
      City: "Chicago",
      Depot: null,
      GateInDepot: null,
      Color: null,
      MfgYear: null,
      DateCreated: "2021-03-13 01:11:44",
      DateAvailable: null,
      DateAllocated: null,
      Status: "ALLOCATE",
      Price: "1000.0000",
      Currency: null,
    });
  }
  return items;
};

export const inventory3 = [
  {
    id: 99034,
    EquipmentID: "ANBU5422590",
    Size: "50",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-08-26 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "1",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99035,
    EquipmentID: "ACMU9329740",
    Size: "10",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:21:11",
    ExpiredDate: "2021-08-26 00:00:00",
    Status: "1",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99036,
    EquipmentID: "ECMU8024449",
    Size: "30",
    Type: "OH",
    Cndtn: "AS IS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "08/31/21",
    Color: null,
    MfgYear: "2003",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-01 00:00:00",
    DateAllocated: "2021-10-06 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-17 13:48:28",
    ExpiredDate: null,
    Status: "2",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 43,
    EstDateOfReturn: "2021-01-16 00:00:00",
    substep_descr: null,
  },
  {
    id: 99037,
    EquipmentID: "ECMU9283296",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "09/02/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-09 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-08 22:29:18",
    ExpiredDate: null,
    Status: "1",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99038,
    EquipmentID: "ECMU4309572",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "08/31/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-08 22:59:19",
    ExpiredDate: null,
    Status: "1",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99039,
    EquipmentID: "ECMU4313870",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "9/8/2021",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-09 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-08 23:16:56",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99040,
    EquipmentID: "ECMU1448656",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "09/02/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-08 23:16:57",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99041,
    EquipmentID: "ECMU9285920",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-08 23:16:56",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99042,
    EquipmentID: "INKU2318604",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99043,
    EquipmentID: "ECMU1424295",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "08/31/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-08 23:16:58",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99044,
    EquipmentID: "INKU2368185",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:43:02",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-08 23:16:59",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99045,
    EquipmentID: "INKU2368182",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-08-24 23:56:18",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-08-24 23:56:18",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99046,
    EquipmentID: "ECMU4304441",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:50",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99047,
    EquipmentID: "ECMU9346183",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99048,
    EquipmentID: "INKU2693077",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99049,
    EquipmentID: "INBU5404745",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99050,
    EquipmentID: "UESU4837267",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99051,
    EquipmentID: "INBU5424783",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99052,
    EquipmentID: "INKU6121439",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99053,
    EquipmentID: "INKU2420998",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99054,
    EquipmentID: "ECMU1446226",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99055,
    EquipmentID: "ECMU8018404",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99056,
    EquipmentID: "ECMU4081306",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99057,
    EquipmentID: "ECMU8020166",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99058,
    EquipmentID: "INBU5435140",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99059,
    EquipmentID: "ECMU9370446",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99060,
    EquipmentID: "TTNU3636530",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99061,
    EquipmentID: "ECMU1467574",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99062,
    EquipmentID: "ECMU1487950",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99063,
    EquipmentID: "ECMU9274524",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99064,
    EquipmentID: "ECMU8013423",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99065,
    EquipmentID: "ECMU9305040",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99066,
    EquipmentID: "ECMU4290137",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99067,
    EquipmentID: "CLHU8541560",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99068,
    EquipmentID: "ECMU8018430",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99069,
    EquipmentID: "TRLU5836032",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99070,
    EquipmentID: "ECMU1482331",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99071,
    EquipmentID: "INBU5435788",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99072,
    EquipmentID: "ECMU9326994",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99073,
    EquipmentID: "INKU6143166",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99074,
    EquipmentID: "TRLU9030277",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-28 08:09:37",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99075,
    EquipmentID: "ECMU8018261",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99076,
    EquipmentID: "ECMU8024480",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99077,
    EquipmentID: "INKU6098895",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99078,
    EquipmentID: "ECMU9232241",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "09/02/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99079,
    EquipmentID: "ECMU9310895",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "09/02/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99080,
    EquipmentID: "APHU6092422",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "08/24/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99081,
    EquipmentID: "ECMU8022512",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "08/24/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-10-05 12:09:08",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99082,
    EquipmentID: "UESU4836743",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: "09/06/21",
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: "2021-09-13 00:00:00",
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-28 10:14:22",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: 1,
    BuyerParty: "Bulk Container Group",
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
  {
    id: 99083,
    EquipmentID: "INKU6095453",
    Size: "30",
    Type: "L",
    Cndtn: "AS",
    Country: "US",
    State: "TX",
    City: "DALLAS",
    Depot: "",
    GateInDepot: null,
    Color: null,
    MfgYear: "2009",
    DateCreated: "2021-09-13 08:31:51",
    DateAvailable: "2021-09-09 00:00:00",
    DateAllocated: null,
    DateSold: "2021-10-05 00:00:00",
    OutDate: "2021-10-05 00:00:00",
    LastModifiedDate: "2021-09-28 08:10:58",
    ExpiredDate: null,
    Status: "3",
    Price: null,
    Currency: "USD",
    BuyerPartyId: null,
    BuyerParty: null,
    Age: 35,
    EstDateOfReturn: null,
    substep_descr: null,
  },
];
export const header = [
  { title: "Select All", value: "All" },
  { title: "Equipment ID", value: "EquipmentID" },
  { title: "Flagged", value: "isFlaggedEnabled" },
  { title: "Size", value: "Size" },
  { title: "Type", value: "Type" },
  { title: "Condition", value: "Cndtn" },
  { title: "Country", value: "Country" },
  { title: "State/Province", value: "State" },
  { title: "City", value: "City" },

  // { title: "Gate In Depot", value: "GateInDepot" },
  { title: "Color", value: "Color" },
  { title: "Mfg Year", value: "MfgYear" },

  { title: "Status", value: "Status" },
  { title: "Status Code", value: "InboundStatusCode" },
  { title: "Status Date", value: "InboundStatusDate" },
  { title: "Status Idle Days", value: "InboundStatusIdleDaysCalculated" },
  { title: "Status Location", value: "InboundStatusLocation" },
  { title: "HubPoint", value: "HubPoint" },
  { title: "Depot", value: "Depot" },

  // { title: "Buyer Party ", value: "BuyerParty" },
  { title: "Price", value: "Price" },
  { title: "Currency", value: "Currency" },
  { title: "Age", value: "Age" },
  { title: "Created Date", value: "DateCreated" },
  { title: "Last Modified Date", value: "LastModifiedDate" },
  { title: "Date Available", value: "DateAvailable" },
  // { title: "Allocated Date", value: "DateAllocated" },
  // { title: "Out Date", value: "OutDate" },
  { title: "Gate Out Date", value: "gated_picked_up_date" },
  // { title: "Depot ETA", value: "EstDateOfReturn" },
  { title: "SmartBox", value: "SmartBox" },
  { title: "Lease Agreement", value: "LeaseAgreement" },
  { title: "Flag Created", value: "flaggedDateCreated" },
  { title: "Flag Updated", value: "flaggedDateUpdated" },
  { title: "Tracking Comments", value: "Comments" },
  { title: "M&R Comments", value: "MRComments" },
  { title: "RF Machinery MFG", value: "RFMachineryMFG" },
  { title: "RF Machinery Model", value: "RFMachineryModel" },
  { title: "SAL Ref", value: "gated_custom_ref_a" },
  { title: "Invoice No", value: "invoice_no" },
  { title: "Invoiced Date", value: "invoiced_date" },
  { title: "Out Release Ref", value: "OutReleaseRef" },
];
export const header2_main = [
  { title: "Select All", value: "All" },
  { title: "Equipment ID", value: "EquipmentID" },
  { title: "Flagged", value: "isFlaggedEnabled" },
  { title: "Size", value: "Size" },
  { title: "Type", value: "Type" },
  { title: "Country", value: "Country" },
  { title: "State/Province", value: "State" },
  { title: "City", value: "City" },
  { title: "Gate In Depot", value: "GateInDepot" },
  { title: "Age", value: "Age" },
  { title: "Last Modified Date", value: "LastModifiedDate" },
];

export const header2_expanded = [
  { title: "Buyer Party ", value: "BuyerParty" },
  { title: "Price", value: "Price" },
  { title: "Currency", value: "Currency" },
  { title: "Created Date", value: "DateCreated" },
  { title: "Date Available", value: "DateAvailable" },
  { title: "Allocated Date", value: "DateAllocated" },
  { title: "Out Date", value: "OutDate" },
  { title: "Depot ETA", value: "EstDateOfReturn" },
  { title: "Smartbox", value: "Smartbox" },
  { title: "Lease Agreement", value: "LeaseAgreement" },
];

export const defaultItemObject = {
  EquipmentID: "",
  Size: "",
  Type: "",
  Cndtn: "",
  Country: "",
  State: "",
  City: "",
  Depot: "",
  GateInDepot: "",
  Color: "",
  MfgYear: "",
  DateCreated: "",
  DateAvailable: "",
  DateAllocated: "",
  DateSold: "",
  OutDate: "",
  LastModifiedDate: "",
  ExpiredDate: "",
  Status: "",
  Price: "0",
  Currency: "",
  BuyerParty: "",
  Age: "",
  EstDateOfReturn: "",
  InboundStatusCode: "",
  InboundStatusDate: "",
  InboundStatusIdleDaysGiven: "",
  InboundStatusIdleDaysCalculated: "",
  HubPoint: "",
  InboundStatusLocation: "",
  SmartBox: "",
  LeaseAgreement: "",
  flaggedDateCreated: "",
  flaggedDateUpdated: "",
  Comments: "",
  select: true,
};

export const nestedMenuData = [
  {
    name: "SELL",
  },
  { isDivider: true },
  {
    name: "ALLOCATE",
    menu: {
      nameParent: "ALLOCATE",
      data: [],
    },
  },
  { isDivider: true },
  {
    name: "SOLD",
    menu: {
      nameParent: "SOLD",
      data: [],
    },
  },
  { isDivider: true },
  { name: "EXPIRE" },
  { isDivider: true },
  { name: "INBOUND" },
  { isDivider: true },
  { name: "IN-CY" },
  { name: "OUT" },
  { isDivider: true },
];

export const INBOUND_PANEl_HEADER = [
  {
    text: "Date",
    value: "date_time",
    align: "center",
    class: "table-header",
  },
  {
    text: "Status",
    value: "status",
    align: "center",
    class: "table-header",
  },
  {
    text: "Description",
    value: "status_description",
    align: "center",
    class: "table-header",
  },
  {
    text: "Location",
    value: "status_location",
    align: "center",
    class: "table-header",
  },
  {
    text: "Dwell",
    value: "dwell",
    align: "center",
    class: "table-header",
  },
  {
    text: "ID",
    value: "status_id",
    align: "center",
    class: "table-header",
  },
];

export const INBOUND_PANEl_EXAMPLE = {
  EquipmentID: "ECMU1435670",
  Size: "20",
  Type: "ST",
  MfgYear: "2003",
  InboundStatusCode: "sell",
  InboundStatusDate: "05/04/2021 12:00",
  InboundStatusIdleDaysCalculated: 150,
  InboundStatusLocation: "USCHIDILL",
  table_data: [
    {
      date_time: "",
      status: "",
      status_description: "",
      status_location: "",
      dwell: "",
    },
  ],
};
