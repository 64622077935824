<template>
  <v-container class="fill-height side-panel pa-0 ma-0 mt-auto elevation-5">
    <div class="panel-wrapper">
      <div class="panel-title">
        <slot name="title" class="title"></slot>
      </div>
      <slot></slot>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss">
.side-panel {
  position: absolute;
  background-color: #1e1e1e;
  right: 0;
  z-index: 3;
  align-content: center;
  justify-content: center;
}

.panel-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  padding: 5px;
  min-height: 0%;
  overflow-y: auto;
  .panel-title {
    background: #3284f4;
    color: #262737;
    border-radius: 0px 0px 5px 5px;
    .header-title {
      /* border-bottom: thin solid #262737; */
      font-size: 1.25rem !important;
      font-weight: bold !important;
      letter-spacing: 0.0525em !important;
      color: white;
      padding: 0.5rem;
      border-radius: 5px;
      line-height: initial;
      justify-content: space-between;
    }
    .header-subtitles {
      gap: 5px;
      text-align: center;
      font-size: 1rem !important;
      font-weight: bold !important;
      letter-spacing: 0.0525em !important;
      /* color: white; */
      padding: 0.5rem;
    }
  }
}
</style>
