export default {
  bind(el, binding, vnode) {
    // const modifiers = binding.modifiers;

    const userType = vnode.context.$store.getters.getUserType;

    if (userType !== "VB") {
      el.style.display = "none";

      if (vnode.elm.parentElement)
        vnode.elm.parentElement.removeChild(vnode.elm);
    }
  },
};
