export const header = [
  { title: "Buyer Party Name", value: "name" },
  { title: "Date Created", value: "DateCreated" },
  { title: "Company Address", value: "address" },
  { title: "Contact Info", value: "contactInfo" },
  { title: "Enable", value: "enabled" },
  { title: "External", value: "external" },
  { title: "Last Modified", value: "DateUpdated" },
  { title: "", value: "Action" },
];

export const buyerData = () => {
  let items = [];
  for (let index = 0; index < 49; index++) {
    items.push({
      id: index + 1,
      externalName: `BCG ${index}`,
      DateCreated: "05/13/2021",
      external: index % 2 === 0 ? "N" : "Y",
      address: "Bulk Container Group",
      contact_info_name: "awasome !",
      contact_info_title: "awesome title",
      contact_info_phone: "555555",
      contact_info_email: "awesome@a.com",
      lastModified: "01/06/2021",
    });
  }
  return items;
};

export const buyerFormDataRows = [
  {
    title: "Buyer Party Info",
    value: "buyerPartyInfo",
    fields: [
      { title: "Name", value: "externalName", rule: "emptyRules" },
      { title: "Company Address", value: "address" },
    ],
  },
  {
    title: "Contact Info",
    value: "contactInfo",
    fields: [
      { title: "Contact Name", value: "contact_info_name" },
      {
        title: "Contact Title",
        value: "contact_info_title",
      },
      {
        title: "Contact Phone",
        value: "contact_info_phone",
      },
      {
        title: "Contact Email",
        value: "contact_info_email",
      },
    ],
  },
];
