export const HEADER = [
  { text: "Auction Id", value: "auctionID" },
  { text: "Auction Name", value: "auctionName" },
  { text: "Type", value: "docTypeVendDesc" },
  { text: "Order Number#", value: "rfq_id" },
  { text: "Doc #", value: "docNo" },
  { text: "Status", value: "vendStepDescr" },
  { text: "Date", value: "docDate" },
  { text: "Created By", value: "origin_login_name" },
  { text: "Customer", value: "coName" },
  { text: "Total Qty", value: "vit_qty" },
  { text: "Total Amount", value: "vit_vendorTotal" },
  { text: "TBD", value: "isTBD" },
  { text: "Qty Pickup", value: "v_picked_up" },
  { text: "Depots", value: "Depots", width: "0px" },
  { text: "Releases", value: "releases_ref", width: "0px" },
  { text: "Container", value: "ContainersNo", width: "0px" },
];

export const HEADER_ORGANIZATIONS = [
  {
    align: "center",
    text: "Name",
    value: "organization_descr",
    class: "table-header",
    sortable: true,
  },
  {
    align: "center",
    text: "Email",
    value: "primary_email",
    class: "table-header",
    sortable: true,
  },
  {
    align: "center",
    text: "type",
    value: "organization_type",
    class: "table-header",
    sortable: true,
  },
];

export const CUSTOMER_HEADER = [{ text: "Name", value: "organization_descr" }];

export const customerFields = [
  {
    title: "Customer Name",
    value: "customerName",
    rules: "required|min:2",
    readonly: null,
  },
  {
    title: "Email",
    value: "primary_email",
    rules: "required|email",
  },
  {
    title: "Address",
    value: "organization_address",
    readonly: null,
  },
  {
    title: "External Customer #",
    value: "externalCustomerNumber",
    // rules: "required|alpha_spaces",
  },
  {
    title: "Customer PO",
    value: "custPoRef",
    // rules: "required|alpha_spaces",
  },
  {
    title: "Contract Reference #",
    value: "contractNumberReference",
    // rules: "required|alpha_spaces",
  },

  {
    title: "Order Type",
    value: "orderTypeId",
    type: "dropdown",
    drowpdownValues: {
      text: "typeDesc",
      value: "id",
      values: null,
    },
  },
  {
    title: "Credit Term",
    value: "creditTermId",
    type: "dropdown",
    drowpdownValues: {
      text: "termsDesc",
      value: "id",
      values: null,
    },
  },
];

export const formOrdersTable = ({ depotSelects }) => [
  {
    text: "Size",
    value: "equipmentSize",
    rules: "required",
  },
  {
    text: "Type",
    value: "equipmentType",
    rules: "required",
  },
  {
    text: "Condition",
    value: "equipmentCondition",
    rules: "required",
  },
  { text: "Container No.", value: "ContainerNo" },
  { text: "MFG Year", value: "ManufactureYear" },
  {
    text: "Depot",
    value: "depot",

    rules: "required",
    type: "dropdown",
    dropdownData: {
      items: depotSelects,
      text: "code",
      value: "code",
    },
  },
  {
    text: "Country",
    value: "country",
    rules: "required|min:2|max:5|alpha",
  },
  {
    text: "State",
    value: "state",
    rules: "required|alpha",
  },
  {
    text: "City",
    value: "city",
    rules: "required|min:2",
  },
  {
    text: "Product",
    value: "temType",
    rules: "required",
    // type: "dropdown",

    // dropdownData: {
    //   items: [{ text: "Container", value: "Container" }],
    //   text: "text",
    //   value: "value",
    // },
  },
  {
    text: "Qty",
    value: "qty",
    rules: "required|min:1|numeric",
  },
  { text: "Price", value: "buyerPrice", rules: "required|min:1|numeric" },
  { text: "Total", value: "total" },

  { text: "", value: "actions" },
  // { text: "Is TBD", value: "isTBD", type: "checkbox", width: "5%" },
];

export const stepCustomerFields = ({ ordersTerm, ordersType, flag }) => {
  let fields = customerFields;

  fields.filter(
    (x) => x.value === "organization_address" || x.value === "customerName",
  )[0].readonly = !flag;

  fields[6].drowpdownValues.values = ordersType;
  fields[7].drowpdownValues.values = ordersTerm;

  return fields;
};
