import { gateType } from "@/helpers/gate_buy.js";
import Vue from "vue";
Vue.directive("gate-buy", {
  update(el, binding, vnode) {
    const ordersType = vnode.context.$store.state.moduleOrders.ordersType;

    const typeData = ordersType.filter((x) => x.id === binding.value);
    const flag = gateType.order_type_desc === typeData[0]?.code;

    if (flag) {
      el.style.display = "none";
    } else {
      el.style.display = "";
    }
  },
});
// export default {
//   update(el, binding, vnode) {
//     console.log(binding.value);
//   },
//   // Get the Vuex store instance
//   //   const store = vnode.context.$store;

//   //   // Define a function to set up the watcher
//   //   const setupWatcher = () => {
//   //     // Get the getter function
//   //     const getterFunction = () => {
//   //       return store.getters[binding.value];
//   //     };

//   //     // Define a watcher to react to changes in the getter function
//   //     const unwatch = vnode.context.$watch(
//   //       getterFunction,
//   //       (newValue, oldValue) => {
//   //         // Perform actions when the getter changes
//   //         console.log("Getter value changed:", oldValue);
//   //         console.log("Getter value changed:", newValue);
//   //         // Update the directive behavior, etc.
//   //       }
//   //     );

//   //     // Ensure that the watcher is destroyed when the directive is unbound
//   //     el.__unwatch__ = unwatch;
//   //   };

//   //   // Use Vue.nextTick to wait for the getter to be initialized
//   //   vnode.context.$nextTick(() => {
//   //     // Set up the watcher
//   //     setupWatcher();
//   //   });
//   // },
//   // unbind(el) {
//   //   // Destroy the watcher when the directive is unbound
//   //   if (el.__unwatch__) {
//   //     el.__unwatch__();
//   //     delete el.__unwatch__;
//   //   }
//   // },
//   // console.log(vnode.context.$options.computed["orderType"]);
//   // const vm = vnode.context;
//   // const computedValue = vm.$options.computed[binding.value];

//   // // console.log(vnode.context.$store.state.moduleOrders.tabSelected.details);
//   // updateDirective();

//   // function updateDirective() {
//   //   console.log(binding.value);
//   // }

//   // vnode.context.$watch(
//   //   () => {
//   //     return vnode.context.$options.computed[binding.value];
//   //   },
//   //   (newValue, oldValue) => {
//   //     // Perform actions when the computed property changes
//   //     console.log("Computed value changed:", newValue);
//   //     // Update the directive behavior, etc.
//   //   }
//   // );
//   // vnode.context.$watch(binding.value, function () {
//   //   // Call the logic function whenever the state changes
//   //   updateDirective();
//   // });
//   // vnode.context.$watch(binding.arg, function () {
//   //   // Call the logic function whenever the state changes
//   //   updateDirective();
//   // });
//   // const ordersType = vnode.context.$store.state.moduleOrders.ordersType;
//   // const currentOrderType =
//   //   vnode.context.$store.state.moduleOrders.tabSelected.details;
//   // console.log(currentOrderType);
//   // console.log(binding.arg);
//   // const typeData = ordersType.filter((x) => x.id === binding.arg);
//   // console.log(typeData);
//   // const flag = gateType.order_type_desc === typeData[0]?.code;
//   // console.log(flag);
//   // if (flag) {
//   //   el.style.display = "none";
//   //   if (vnode.elm.parentElement)
//   //     vnode.elm.parentElement.removeChild(vnode.elm);
//   // }
//   // },
// };
