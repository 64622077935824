import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/global-components";
import "./plugins/vee-validate";
// import './registerServiceWorker'

// import "./scss/mixin.scss";

// import VueCompositionApi from "@vue/composition-api";

Vue.config.productionTip = false;

// let env = process.env
// Object.keys(env).forEach(function(key) {
//   console.log('export ' + key + '="' + env[key] + '"')
// })

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
