import axios from "axios";
import {
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
} from "../indexv2";

const get_sql_reports = async (queryStrings) => {
  const token = localStorage.getItem("access_token");
  try {
    let url = `${apiBaseUrl}/inventory/sqlreport`;

    if (queryStrings.hasOwnProperty("getList")) {
      url = url + "?getList=true";
    } else {
      url = url + `?name=${queryStrings.name}`;
    }

    console.log("url", url);

    const res = await axios({
      method: "GET",
      url,
      headers: { authorization: token },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export { get_sql_reports };
