export const HEADER_CONTACTS = [
  {
    align: "center",
    text: "Contact name",
    value: "contact_name",
    class: "table-header",
  },
  {
    align: "center",
    text: "Title",
    value: "contact_title",
    class: "table-header",
  },
  {
    align: "center",
    text: "Email",
    value: "contact_email",
    class: "table-header",
  },
  {
    align: "center",
    text: "Phone",
    value: "contact_phone",
    class: "table-header",
  },
  {
    align: "center",
    text: "",
    value: "actions",
    class: "table-header",
    sort: "false",
    width: "0px",
  },
];

export const contacts = [
  {
    code: "MKDEPOT2",
    name: "MK Depot 2",
    address: "MK Depot 2 address",
    phone: "MK Depot 2 phone",
    city_id: 12,
    postcode: "46100",
    website: "www.mkdepot2.com",
    email: "MK Depot 2 phone",
    pickup_hours: "8-11am",
    latitude: "12345",
    longitude: "12345",
  },
];

export const HEADER_SALES_REPS = [
  {
    align: "center",
    text: "Contact name",
    value: "contact_name",
    class: "table-header",
  },
  {
    align: "center",
    text: "Title",
    value: "contact_title",
    class: "table-header",
  },
  {
    align: "center",
    text: "Email",
    value: "contact_email",
    class: "table-header",
  },
  {
    align: "center",
    text: "Phone",
    value: "contact_phone",
    class: "table-header",
  },
  {
    align: "center",
    text: "priority sequence",
    value: "priority",
    class: "table-header",
  },
  {
    align: "center",
    text: "",
    value: "actions",
    class: "table-header",
  },
];
export const sales_reps = [
  {
    index: 0,
    sales_reps_name: "sales reps name",
    email: "test@nereus.io",
    priority_seq: 0,
    edit: false,
  },
];

export const custom_fields = [
  {
    index: 0,
    fieldname: "External CRM ID",
    fieldvalue: "#442453",

    edit: false,
  },
];

export const HEADER_CRM_NON_QUOTE_ORDERS = [
  // {
  //   text: "Type",
  //   value: "docTypeVendDesc",
  //   align: "center",
  //   class: "table-header",
  // },
  {
    text: "Order Number#",
    value: "rfq_id",
    align: "center",
    class: "table-header",
  },
  { text: "Doc #", value: "docNo", align: "center", class: "table-header" },
  {
    text: "Status",
    value: "vendStepDescr",
    align: "center",
    class: "table-header",
  },
  { text: "Date", value: "docDate", align: "center", class: "table-header" },
  {
    text: "Created By",
    value: "origin_login_name",
    align: "center",
    class: "table-header",
  },
  { text: "Customer", value: "coName", align: "center", class: "table-header" },
  {
    text: "Total Qty",
    value: "totalQty",
    align: "center",
    class: "table-header",
  },
  {
    text: "Total Amount",
    value: "total",
    align: "center",
    class: "table-header",
  },
];

export const HEADER_QUOTES = [
  {
    text: "Type",
    value: "docTypeVendDesc",
    align: "center",
    class: "table-header",
  },
  {
    text: "Order Number#",
    value: "rfq_id",
    align: "center",
    class: "table-header",
  },
  { text: "Doc #", value: "docNo", align: "center", class: "table-header" },
  {
    text: "Status",
    value: "vendStepDescr",
    align: "center",
    class: "table-header",
  },
  { text: "Date", value: "docDate", align: "center", class: "table-header" },
  {
    text: "Created By",
    value: "origin_login_name",
    align: "center",
    class: "table-header",
  },
  { text: "Customer", value: "coName", align: "center", class: "table-header" },
  {
    text: "Total Qty",
    value: "totalQty",
    align: "center",
    class: "table-header",
  },
  {
    text: "Total Amount",
    value: "total",
    align: "center",
    class: "table-header",
  },
];

export const quotes = [
  {
    index: 0,
    date: "06/16/2021",
    quote_ref: "NO00000",
    country: "USA",
    state: "Ilinois",
    city: "chicago",
    unit_price: "3.000.000",
    total_price: "3.000.000",
    size: "20",
    type: "HC",
    condition: "new",
    sales_rep: "Mike Kang",
  },
];

export const HEADER_DOCUMENTS = [
  {
    align: "center",
    text: "date",
    value: "createdDate",
    class: "table-header",
  },
  {
    align: "center",
    text: "filename",
    value: "filename",
    class: "table-header",
  },
  {
    align: "center",
    text: "permissions",
    value: "permisions",
    class: "table-header",
  },
  {
    align: "center",
    text: "type",
    value: "sourceType",
    class: "table-header",
  },
  {
    align: "center",
    text: "",
    value: "actions",
    class: "table-header",
  },
];

export const HEADER_FIELDS = [
  {
    align: "center",
    text: "Date",
    value: "DateCreated",
    class: "table-header",
  },
  {
    align: "center",
    text: "Field Name",
    value: "FieldName",
    class: "table-header",
  },
  {
    align: "center",
    text: "Field Value",
    value: "value",
    class: "table-header",
  },
  {
    align: "center",
    text: "",
    value: "actions",
    class: "table-header",
  },
];

export const documents = [
  {
    index: 0,
    date: "06/16/2021",
    filename: "example-agreement.pdf",
    permissions: "READ",
  },
];

export const default_org = {
  organization_descr: "",
  organization_type: "C",
  organization_address: "",
  organization_state: "",
  organization_city: "",
  organization_country: "",
  organization_url: "",
  primary_email: "",
  fax_no: "",
  phone_no: "",
  // logo: "",
  rating: 0,
  competitors: "",
  social_media: "",
  notes: "",
  status: "Active",
  organization_postalcode: "",
  ShipAddrLine1: "",
  ShipAddrCity: "",
  ShipAddrState: "",
  ShipAddrCountry: "",
  ShipAddrPostalCode: "",
  DisplayName: "",
  PrintName: "",
  GivenName: "",
  FamilyName: "",
  MiddleName: "",
  MobileNo: "",
  company_no: "",
  tax_no: "",
  externalCustomerNumber: "",
};

export const HEADER_TRANSACTION_HISTORY = [
  {
    align: "center",
    text: "Type",
    value: "Type",
    class: "table-header",
  },
  {
    align: "center",
    text: "Date",
    value: "Date",
    class: "table-header",
  },
  {
    align: "center",
    text: "TX Ref",
    value: "TXRef",
    class: "table-header",
  },
  {
    align: "center",
    text: "Order #",
    value: "Order#",
    class: "table-header",
  },
  {
    align: "center",
    text: "Status",
    value: "Status",
    class: "table-header",
  },
  {
    align: "center",
    text: "Created By",
    value: "Created By",
    class: "table-header",
  },
  {
    align: "center",
    text: "Transaction Amt",
    value: "Transaction Amnt",
    class: "table-header",
  },
  {
    align: "center",
    text: "Applied to",
    value: "Applied To",
    class: "table-header",
  },
  {
    align: "center",
    text: "Order Bal",
    value: "orderBalance",
    class: "table-header",
  },
  {
    align: "center",
    text: "Account Bal",
    value: "accountBalance",
    class: "table-header",
  },
  // {
  //   align: "center",
  //   text: "",
  //   value: "actions",
  //   class: "table-header",
  //   sort: "false",
  //   width: "0px",
  // },
];

export const transactionHistory = [
  {
    type: "ORDER",
    date: "06/04/2022",
    tx_ref: "ORD0002",
    doc_no: "ORD0002",
    status: "New",
    login: "username",
    total: "$10,000",
    doc_ref: "ORD0002",
    order_balance: "$10,000",
    account_balance: "$10,000",
  },
  {
    type: "PAYMENT",
    date: "06/03/2022",
    tx_ref: "PMT001",
    doc_no: "ORD0001",
    status: "Posted",
    login: "username",
    total: "$10,000",
    doc_ref: "ORD0001",
    order_balance: "",
    account_balance: "$0",
  },
  {
    type: "ORDER",
    date: "06/01/2022",
    tx_ref: "ORD0001",
    doc_no: "ORD0001",
    status: "Completed",
    login: "username",
    total: "$10,000",
    doc_ref: "ORD0002",
    order_balance: "$10,000",
    account_balance: "$10,000",
  },
];
