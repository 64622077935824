import axios from "axios";
import { apiBaseUrl } from "../indexv2";

const get_list = async () => {
  const token = localStorage.getItem("access_token");
  return await axios({
    method: "GET",
    url: `${apiBaseUrl}/customer/distributionlist`,
    headers: { authorization: token },
  });
};

const delete_list = async (listId) => {
  const token = localStorage.getItem("access_token");
  return await axios({
    method: "DELETE",
    url: `${apiBaseUrl}/customer/distributionlist`,
    params: {
      listId,
    },
    headers: { authorization: token },
  });
};

const post_list = async (data) => {
  const token = localStorage.getItem("access_token");
  return await axios({
    method: "POST",
    url: `${apiBaseUrl}/customer/distributionlist`,
    headers: { authorization: token },
    data,
  });
};

const edit_list = async (data) => {
  const token = localStorage.getItem("access_token");
  return await axios({
    method: "PUT",
    url: `${apiBaseUrl}/customer/distributionlist`,
    headers: { authorization: token },
    data,
  });
};

export { get_list, delete_list, post_list, edit_list };
