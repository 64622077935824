import axios from "axios";

import {
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
} from "../indexv2";

const createVendorDepotManage = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    data.date_to =
      data.date_to && data.date_to.length > 0 ? data.date_to : undefined;
    axios({
      method: "POST",
      url: `${apiBaseUrl}/depots/`,
      headers: { authorization: token },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        reject(err.response.data);
      });
  });

const updateVendorDepotManage = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "PUT",
      url: `${apiBaseUrl}/depots/`,
      headers: { authorization: token },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        reject(err.response.data);
      });
  });

export { createVendorDepotManage, updateVendorDepotManage };
