import axios from "axios";
import { apiBaseUrl } from "../indexv2";

const create_auction = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/auctions`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const get_auctions = async ({ offset, search, LIMIT, status }) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/auctions`,
      headers: { authorization: token },
      params: {
        offset,
        search,
        LIMIT,
        status,
      },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const get_auction_by_id = async ({ id }) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/auctions/items`,
      headers: { authorization: token },
      params: {
        auctionid: id,
      },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const delete_auction = async ({ id, action }) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/auctions`,
      headers: { authorization: token },
      params: {
        auctionid: id,
        ...(action === "expired" ? { action } : {}),
      },
    });
    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const get_customers_bids = async ({ auctionId, auctionItemID }) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/auctions/customers/bids`,
      headers: { authorization: token },
      params: {
        auctionid: auctionId,
        auctionitem: auctionItemID,
      },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const get_history_bids = async ({ auctionId, auctionItemID }) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/auctions/bids`,
      headers: { authorization: token },
      params: {
        auctionid: auctionId,
        auctionitem: auctionItemID,
      },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const get_customers = async ({ auctionId }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/auctions/customers`,
    headers: { authorization: token },
    params: {
      auctionid: auctionId,
    },
  });

  return res.data.data;
};
const get_bids = async ({ id, time }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/auctions/bids/history`,
    headers: { authorization: token },
    params: {
      auctionid: id,
      time,
    },
  });

  return res.data.data;
};
const update_auction_line_items = async ({ auctionId, data }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/auctions/addadditionalitems`,
    headers: { authorization: token },
    params: {
      auctionid: auctionId,
    },
    data: {
      auctionId,
      ...data,
    },
  });

  return res.data;
};

const update_auction = async ({ auctionId, data }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "PUT",
    url: `${apiBaseUrl}/auctions`,
    headers: { authorization: token },
    params: {
      auctionid: auctionId,
    },
    data,
  });

  return res.data;
};
const update_lot_comments = async ({ data }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "PUT",
    url: `${apiBaseUrl}/auctions/items/comment`,
    headers: { authorization: token },

    data,
  });

  return res.data;
};
const delete_lot = async (auctionItemId) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "DELETE",
    url: `${apiBaseUrl}/auctions/items/${auctionItemId}`,
    headers: { authorization: token },
  });

  return res.data;
};
const get_winners = async ({ auctionid }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/auctions/items/winners`,
    headers: { authorization: token },
    params: {
      auctionid,
    },
  });

  return res.data.data;
};
const convert_auction = async (data) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/orders`,
    headers: { authorization: token },
    data,
  });

  return res.data.data;
};

const convert_auction_bulk = async (data) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/orders/bulk`,
    headers: { authorization: token },
    data,
  });

  return res.data.data;
};
const post_carbon_copy = async ({ email }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/auctions/carboncopy`,
    headers: { authorization: token },
    data: { email },
  });

  return res.data.data;
};
const get_carbon_copy = async () => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/auctions/carboncopy`,
    headers: { authorization: token },
  });

  return res.data.data;
};
const get_damage_details = async (containers) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/auctions/damagedetails`,
    headers: { authorization: token },
    data: {
      containers,
    },
  });

  return res.data.data;
};

const get_last_cust = async (containers) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/auctions/last/users`,
    headers: { authorization: token },
  });

  return res.data.data;
};

const set_manual_winner = async ({ auctionId, auctionItemID, CustomerId }) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/auctions/manualWinnerBid`,
    headers: { authorization: token },
    data: {
      auctionId: Number(auctionId),
      auctionItemID: Number(auctionItemID),
      CustomerId,
    },
  });

  return res.data;
};

const get_saved_auction = async () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "GET",
    url: `${apiBaseUrl}/auctions/draft`,
    headers: { authorization: token },
  });
};
const post_saved_auction = async (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/auctions/draft`,
    headers: { authorization: token },
    data,
  });
};
const delete_saved_auction = async (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "DELETE",
    url: `${apiBaseUrl}/auctions/draft`,
    headers: { authorization: token },
  });
};

const add_customer = async (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/auctions/addnewcustomers`,
    headers: { authorization: token },
    data,
  });
};

export {
  add_customer,
  create_auction,
  get_auctions,
  get_auction_by_id,
  delete_auction,
  get_customers_bids,
  get_history_bids,
  get_customers,
  get_bids,
  update_auction,
  update_lot_comments,
  delete_lot,
  get_winners,
  convert_auction,
  post_carbon_copy,
  get_carbon_copy,
  get_damage_details,
  get_last_cust,
  set_manual_winner,
  get_saved_auction,
  post_saved_auction,
  delete_saved_auction,
  convert_auction_bulk,
  update_auction_line_items,
};
