import axios from "axios";

const corsAny = "https://corsanywhere-vjuqlq6gsq-wl.a.run.app";
const urlOldApiEndpoint = `${corsAny}/${process.env.VUE_APP_API_PATH}/v1`; // OLD SC API PROXY URL
const urlScriptCaseDirect = `${corsAny}/${process.env.VUE_APP_SCRIPTCASE_API_PATH}`;

//corsanywhere-vjuqlq6gsq-wl.a.run.app/https://nereus-sc-dev-b.myscriptcase.com/scriptcase91/app/iConEXapi_country_state_city/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eSI6InlhbGlAbmVyZXVzLmlvIiwidXNlcl9jbGFpbXMiOnsibmFtZSI6IllhbGkiLCJsb2dpbiI6InlhbGlAbmVyZXVzLmlvIiwiZW1haWwiOiJ5YWxpQG5lcmV1cy5pbyIsImFjdGl2ZSI6IlkiLCJwb2xpY3kiOm51bGwsIm9yZ2FuaXphdGlvbl9pZCI6MTE1LCJvcmdhbml6YXRpb25fZGVzY3IiOiJWZW5kb3IgQUJDIiwib3JnYW5pemF0aW9uX3R5cGUiOiJWIiwidXNlcl9ncm91cF9pZCI6MTAsInVzZXJfZ3JvdXBfZGVzY3JpcHRpb24iOiJWZW5kb3IgVmlldyJ9LCJpYXQiOjE3MDM3MDM0OTgsImV4cCI6MTcwNDMwODI5OH0.5M5mpBK8oHHOjX0NKBZr0GwW7wfjus5CINuf5wgZ4G0&type=country
// https: //corsanywhere-vjuqlq6gsq-wl.a.run.app/https://nereus-sc-dev-b.myscriptcase.com/scriptcase91/app/iConEX/api_country_state_city/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eSI6InlhbGkrY3VzdDJAbmVyZXVzLmlvIiwidXNlcl9jbGFpbXMiOnsibmFtZSI6IkN1c3QgT3JnIDIiLCJsb2dpbiI6InlhbGkrY3VzdDJAbmVyZXVzLmlvIiwiZW1haWwiOiJ5YWxpK2N1c3QyQG5lcmV1cy5pbyIsImFjdGl2ZSI6IlkiLCJwb2xpY3kiOiJZIiwib3JnYW5pemF0aW9uX2lkIjoxMjk5LCJvcmdhbml6YXRpb25fZGVzY3IiOiJDdXN0IE9yZyAyIiwib3JnYW5pemF0aW9uX3R5cGUiOiJDIiwidXNlcl9ncm91cF9pZCI6bnVsbCwidXNlcl9ncm91cF9kZXNjcmlwdGlvbiI6bnVsbH0sImlhdCI6MTcwNDI5MDcwMywiZXhwIjoxNzA0ODk1NTAzfQ.o4EQpHy8VkWp0Kfbs5ClTDUsi2S9Dk4wXyrvpy72a00&type=country

const api_get_all_countries = async () => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    url: `${urlScriptCaseDirect}/api_country_state_city/?token=${token}&type=country`,
  });
};

const api_get_states_by_country = async (id) => {
  const token = localStorage.getItem("access_token");

  const { data } = await axios({
    method: "GET",
    url: `${urlScriptCaseDirect}/api_country_state_city/?token=${token}&type=state&id=${id}`,
  });

  data.data.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

  return data.data;
};

const api_get_cities_by_state = async (id) => {
  const token = localStorage.getItem("access_token");

  let { data } = await axios({
    method: "GET",
    url: `${urlScriptCaseDirect}/api_country_state_city/?token=${token}&type=city&id=${id}`,
  });
  return data.data;
};

const api_get_sizes = async () => {
  const token = localStorage.getItem("access_token");

  let response = await axios({
    method: "GET",
    url: `${urlScriptCaseDirect}/api-container-data/?token=${token}&type=size`,
  });

  return response.data.data;
};
export {
  api_get_all_countries,
  api_get_states_by_country,
  api_get_cities_by_state,
  api_get_sizes,
};
