import axios from "axios";

import { apiBaseUrl } from "../indexv2";
const get_depot_all_sale_hold = async () => {
  return await axios({
    method: "GET",
    url: `${apiBaseUrl}/depots/salehold`,
    params: {
      depot: "USMEMDINC",
    },
  });
};

export { get_depot_all_sale_hold };
