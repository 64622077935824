<template>
  <modal
    title="Confirm"
    width="250px"
    :show="show"
    background="white"
    toolbarColor="white"
    :toolbar="false"
  >
    <template v-slot>
      <v-row class="text-h6 pt-4 white" justify="center">
        <v-col cols="10" class="font-weight-medium text-capitalize black--text">
          <p class="ma-0">Confirm</p>

          <p class="ma-0 pt-2 black--text text-subtitle-1">Are you sure?</p>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-row justify="end" style="gap: 5px">
        <v-col cols="4" xs="6">
          <v-btn
            class="elevation-5 font-weight-bold"
            block
            color="primary"
            text
            @click="$emit('removeAuction')"
          >
            yes
          </v-btn>
        </v-col>

        <v-col cols="4" xs="6">
          <v-btn
            class="elevation-5 red font-weight-bold"
            block
            text
            @click="$emit('closeConfirmCancelAuction')"
          >
            no
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { mapActions } from "vuex";

export default {
  props: ["show"],
  components: {
    Modal,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.border-color-primary {
  border: solid thin #3284f4;
  //   border-color: #3284f4;
}
.border-bottom-primary {
  border-bottom: solid thin #3284f4;
}
</style>
