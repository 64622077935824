import axios from "axios";

import {
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
} from "../indexv2";

const getListOfFilesByOrganization = (id) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    console.log("ID ENDPOINT: ", id);
    axios({
      method: "GET",
      url: `${apiBaseUrl}/files/organization?organization_id=${id}`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const getSignedURL = (fileName) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/files/signedurl?name=${fileName}`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const uploadFile = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    console.log("endpoint data: ", data);

    axios({
      method: "POST",
      url: `${apiBaseUrl}/files/upload`,
      headers: { authorization: token },
      data,
    })
      .then((res) => {
        console.log("upload file response: ", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("upload file error: ", err);
        reject(err.response);
      });
  });

const deleteFile = (fileName) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "DELETE",
      url: `${apiBaseUrl}/files/delete?name=${fileName}`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const makePublicFile = (fileName) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/files/public?name=${fileName}`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const getFilesBySourceID = (orderid) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/files/order?orderid=${orderid}`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const getQuotesAndOpenOrders = (custID) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/all/openandquotes?custID=${custID}`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const getCompletedOrders = (custID) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/all/completed?custID=${custID}`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const getHistoryOrders = (custID) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/all/history?custID=${custID}`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const getOrganizationConfig = () =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/organizationconfig`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const createOrganizationConfig = (release_option) =>
  new Promise((resolve, reject) => {
    console.log("create");
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/organizationconfig`,
      headers: { authorization: token },
      data: {
        release_option,
      },
    })
      .then((res) => {
        console.log("create response", res);
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const updateOrganizationConfig = (release_option, id) =>
  new Promise((resolve, reject) => {
    console.log("update");
    const token = localStorage.getItem("access_token");
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/organizationconfig`,
      headers: { authorization: token },
      data: {
        release_option,
        id,
      },
    })
      .then((res) => {
        console.log("update config response", res);
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const deleteOrganizationConfig = (id) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "DELETE",
      url: `${apiBaseUrl}/organizationconfig`,
      headers: { authorization: token },
      data: {
        id,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const getTransactionHistoryByCostumer = (custID) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/transaction/history?custID=${custID}`,
      headers: { authorization: token },
      data: {
        custID: custID,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response);
      });
  });

const uploadMasterContract = async (data) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "POST",
    url: `${apiBaseUrl}/auctions/mastercontract`,
    headers: { authorization: token },
    data,
  });

  return res.data;
};

const createHoldRate = async (data) => {
  return await axios({
    method: "POST",
    url: `${apiBaseUrl}/gb/rate`,
    data,
  });
};
const updateHoldRate = async (data) => {
  return await axios({
    method: "PUT",
    url: `${apiBaseUrl}/gb/rate/${data.id}`,
    data,
  });
};
const getAllHoldRate = async () => {
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/gb/rate`,
  });

  return res.data.data.map(({ Size, Type, rate, Location }) => {
    return { Size, Type, rate, Location };
  });
};
const getHoldRateByCust = async (customerID) => {
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/gb/rate/customer/${customerID}`,
  });

  return res.data.data.map(({ Size, Type, rate, id, Location }) => {
    return { Size, Type, rate, id, Location };
  });
};
const delateHoldRate = async (id) => {
  return await axios({
    method: "DELETE",
    url: `${apiBaseUrl}/gb/rate/${id}`,
  });
};

const getContainerRates = async (containersNo) => {
  return await axios({
    method: "POST",
    url: `${apiBaseUrl}/gb/container/rates`,
    data: {
      Containers: containersNo,
    },
  });
};
const createQuota = async (data) => {
  return await axios({
    method: "POST",
    url: `${apiBaseUrl}/gb/quota`,
    data,
  });
};
const updateQuota = async (data) => {
  return await axios({
    method: "PUT",
    url: `${apiBaseUrl}/gb/quota/${data.id}`,
    data,
  });
};
const getAllQuotas = async () => {
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/gb/quota`,
  });

  return res.data.data.map(({ Size, Type, rate }) => {
    return { Size, Type, rate };
  });
};
const getQuotasByCust = async (customerID) => {
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/gb/quota/customer/${customerID}`,
  });

  return res.data.data.map(({ Size, Type, Location, quota, id }) => {
    return { Size, Type, Location, quota, id };
  });
};
const deleteQuota = async (id) => {
  return await axios({
    method: "DELETE",
    url: `${apiBaseUrl}/gb/quota/${id}`,
  });
};

export {
  makePublicFile,
  deleteFile,
  uploadFile,
  getSignedURL,
  getListOfFilesByOrganization,
  getFilesBySourceID,
  getQuotesAndOpenOrders,
  getCompletedOrders,
  getHistoryOrders,
  getOrganizationConfig,
  createOrganizationConfig,
  updateOrganizationConfig,
  deleteOrganizationConfig,
  getTransactionHistoryByCostumer,
  uploadMasterContract,
  createHoldRate,
  getAllHoldRate,
  getHoldRateByCust,
  updateHoldRate,
  delateHoldRate,
  getContainerRates,
  createQuota,
  getAllQuotas,
  getQuotasByCust,
  updateQuota,
  deleteQuota,
};
