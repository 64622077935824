import axios from "axios";
import {
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
} from "../indexv2";

const get_report_end_of_month = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/report/endofmonth`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_report_end_of_month_2 = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/report/endofmonthsal`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_report_massrebill = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/report/massrebill`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const get_report_salepending = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/report/salepending`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_report_salepending_2 = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/report/salependingv2`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_report_salepending_gb = async (data) => {
  const token = localStorage.getItem("access_token");

  return await axios({
    method: "POST",
    url: `${apiBaseUrl}/report/salependinggb`,
    headers: { authorization: token },
    data,
  });
};
const download_report = async ({ name }) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/report/`,
      headers: { authorization: token },
      params: {
        name,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const get_repott_general = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/report/reportGeneral`,
      headers: { authorization: token },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const download_bi_weekly_report = async () => {
  const token = localStorage.getItem("access_token");

  return await axios({
    method: "GET",
    url: `${apiBaseUrl}/orders/export`,
    headers: { authorization: token },
  });
};

const get_release_issues = async () => {
  const token = localStorage.getItem("access_token");

  return await axios({
    method: "GET",
    url: `${apiBaseUrl}/report/getReleaseIssues`,
    headers: { authorization: token },
  });
};

const upload_mass_rebill = async (data) => {
  const token = localStorage.getItem("access_token");

  return await axios({
    method: "POST",
    url: `${apiBaseUrl}/orders/invoice/meta/file`,
    headers: { authorization: token },
    data,
  });
};
export {
  get_report_end_of_month,
  get_report_massrebill,
  download_report,
  get_report_salepending,
  get_report_salepending_2,
  get_report_end_of_month_2,
  get_repott_general,
  download_bi_weekly_report,
  upload_mass_rebill,
  get_release_issues,
  get_report_salepending_gb,
};
