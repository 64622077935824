<template>
  <v-dialog v-model="show" :width="width" class="modal elevation-5" persistent>
    <v-card class="modal-card">
      <v-toolbar
        v-if="toolbar === undefined || toolbar"
        :color="toolbarColor ? toolbarColor : '#3284f4'"
        class="pa-2 rounded-0 ma-0 elevation-5 toolbar"
        height="auto"
        rounded="0"
        dense
      >
        <slot name="title" />
        <span v-if="!$scopedSlots[`title`]">{{ title }} </span>
      </v-toolbar>
      <v-row class="pa-0 ma-0 flex-column">
        <slot> </slot>
      </v-row>

      <v-card-actions :style="{ backgroundColor: background }">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["width", "show", "title", "background", "toolbarColor", "toolbar"],
};
</script>

<style lang="scss" scoped>
.modal {
  max-height: 80% !important;
  z-index: 0;
}
.v-text-field--outlined >>> fieldset {
  border-color: #3284f4;
}

button {
  border: thin #20212e solid;
}

.toolbar {
  justify-content: flex-end !important;

  border-color: #3284f4;
  color: #20212e;
  font-weight: bolder;
}
</style>

<style lang="scss">
.toolbar {
  .v-toolbar__content {
    justify-content: flex-end !important;
  }
}
</style>
