export const header = [
  { title: "Country", value: "Country" },
  { title: "State", value: "State" },
  { title: "City", value: "City" },
  { title: "Depot", value: "Depot" },
  { title: "Size", value: "Size" },
  { title: "Type", value: "Type" },
  { title: "Condition", value: "Cndtn" },
  { title: "Buyer", value: "PartnerID" },
  { title: "Qty", value: "QtyAllocated" },
  { title: "Size", value: "Size" },
  { title: "Expiry", value: "ExpiryPeriod" },
  { title: "Expiry Type", value: "ExpiryTimeType" },
  { title: "", value: "Action" },
];

export const inventoryAllocationData = () => {
  let items = [];
  for (let index = 0; index < 49; index++) {
    items.push({
      id: index + 1,
      Country: "US",
      State: `USCHI`,
      City: "Chicago",
      Depot: "USCHIDILT",
      Size: "40",
      Type: "DRY",
      Cndtn: "ASIS",
      PartnerID: 123,
      QtyAllocated: 3,
      ExpiryPeriod: 99,
      ExpiryTimeType: "Years",
    });
  }
  return items;
};

export const inventoryAllocationFormDataRows = [
  {
    title: "Inventory Allocation",
    value: "inventoryAllocation",
    fields: [
      { title: "Country", value: "Country", rules: "required", type: "Select" },
      {
        title: "State",
        value: "State",
        rules: "required",
        type: "Select",
      },
      {
        title: "City",
        value: "City",
        rules: "required",
        type: "Select",
      },
      {
        title: "Depot",
        value: "Depot",
        rules: "required",
        type: "Select",
      },
      {
        title: "Size",
        value: "Size",
        rules: "required",
        type: "Select",
      },
      {
        title: "Type",
        value: "Type",
        rules: "required",
        type: "Select",
      },
      {
        title: "Condition",
        value: "Cndtn",
        rules: "required",
        type: "Select",
      },
      {
        title: "Buyer",
        value: "PartnerID",
        rules: "required",
        type: "Select",
      },
      {
        title: "Qty",
        value: "QtyAllocated",
        rules: "required",
        type: "Text",
      },
      {
        title: "Expiry",
        value: "ExpiryPeriod",
        rules: "required",
        type: "Text",
      },
      {
        title: "Expiry Type",
        value: "ExpiryTimeType",
        rules: "required",
        type: "Select",
      },
    ],
  },
];
