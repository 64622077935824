export const header = [
  { text: "Code", value: "code" },
  { text: "Name", value: "name" },
  { text: "Address", value: "address" },
  { text: "Email", value: "email" },
  { text: "Phone", value: "phone" },
  { text: "Ext", value: "ext" },
  { text: "Pickup Hours", value: "pickup_hours" },
  { text: "Operating Hours", value: "operating_days" },
  { text: "Type", value: "type" },
  { text: "Status", value: "Depot_Status", width: "100px" },
  // { text: "Depot Owner", value: "depot_owner" },
  { text: "", value: "Action", width: "100px" },
];

export const depotManageData = () => {
  let items = [];
  for (let index = 0; index < 49; index++) {
    items.push({
      id: index + 1,
      code: "USATLDRDO",
      name: "RoadOne",
      address: "1952 Moreland Ave, S.E. Atlanta, GA. 30315",
      email: "AtlantaCY@roadone.com",
      phone: "(404) 624-8000",
      ext: "x4040",
      pickup_hours: "Hours for pickup: 0800-1700",
      operating_days: "Mon-Fri",
      type: "Depot",
      depot_owner: "PLACEHOLDER",
    });
  }
  return items;
};

export const depotManageFormDataRows = [
  {
    title: "Depot Manage",
    value: "depotManage",
    fields: [
      {
        title: "* Code (Max 10)",
        value: "code",
        rules: "required|not_space",
        type: "Text",
      },
      {
        title: "* Name",
        value: "name",
        rules: "required",
        type: "Text",
      },

      {
        title: "* Email",
        value: "email",
        rules: "required",
        type: "Text",
      },
      {
        title: "Address",
        value: "address",
        rules: "",
        type: "Text",
      },

      {
        title: "Phone",
        value: "phone",
        type: "Text",
        rules: "",
      },
      {
        title: "Ext",
        value: "ext",
        rules: "",
        type: "Text",
      },
      {
        title: "Pickup Hours [Eg. '0700-1600']",
        value: "pickup_hours",
        rules: "",
        type: "Text",
      },
      {
        title: "Operating Hours [Eg. 'Mon-Fri']",
        value: "operating_days",
        rules: "",
        type: "Text",
      },
      {
        title: "Type [Eg. 'Depot', 'Rail']",
        value: "type",
        rules: "",
        type: "Text",
      },
      {
        title: "Access Requirements",
        value: "access_requirements",
        rules: "",
        type: "Text",
      },
      // {
      //   title: "Depot Owner",
      //   value: "depot_owner",
      //   rules: "",
      //   type: "Text",
      // },
    ],
  },
];
