export const HEADER_QTYSUMM = [
  { text: "Country", value: "CountryAlhpa2" },
  { text: "City", value: "CityName" },
  { text: "Depot Code", value: "Depot" },
  { text: "Size", value: "Size" },
  { text: "Type", value: "Type" },
  { text: "Condition", value: "Cndtn" },
  { text: "Inbound", value: "QtyInbound" },
  { text: "Flagged", value: "QtyFlagged" },
  { text: "On Hand Total", value: "QtyOnHandTotal" },
  { text: "Reserved Auctions", value: "QtyAuctionReservedAll" },

  { text: "Reserved Orders", value: "QtyReserved" },

  { text: "Booked", value: "QtyBooked" },

  {
    text: "On Hand Available (-GB WSM)",
    value: "QtyOnHandAvailable",
  },

  { text: "Over-Booked", value: "OverBooked" },
  // { text: "R Auctions Active", value: "QtyAuctionReservedActive" },
  // { text: "R Auctions Expired", value: "QtyAuctionReservedExpired" },

  // { text: "On Hand Available (+GB WSM)", value: "QtyOnHandAvailable_preGB" },
  // { text: "Gate Buy WSM", value: "QtyGateBuyReserved" },
];

export const HEADER_QTYSUMM_DEPOTS = [
  { text: "Country", value: "CountryAlhpa2" },
  { text: "City", value: "CityName" },
  { text: "Depot Code", value: "Depot" },
  { text: "Size", value: "Size" },
  { text: "Type", value: "Type" },

  { text: "On Hand Total", value: "QtyOnHandTotal" },
  { text: "On Hand Available", value: "QtyOnHandAvailable" },
];

export const INVENTORY_WARNINGS = [
  { text: "container_no", value: "container_no" },
  { text: "message", value: "message" },
  { text: "response", value: "response" },
  { text: "processor_target", value: "processor_target" },
  { text: "original_field", value: "original_field" },

  { text: "original_value", value: "original_value" },
  { text: "incoming_field", value: "incoming_field" },
  { text: "incoming_value", value: "incoming_value" },
  { text: "date_created", value: "date_created" },
];

export const INVENTORYQTYSUMMDATA = () => {
  let items = [];

  items.push({
    id: 1,
    Country: "US",
    City: "MEMPHIS",
    depot_name: "Memphis IMC",
    depot_code: "USMEMDINC",
    Size: "40",
    Type: "HC",
    Cndtn: "AS IS",
    QtyInbound: "50",
    QtyOnHandTotal: "1",
    QtyOnHandAvailable: "1",
    QtyBooked: "0",
    OverBooked: "N",
  });

  items.push({
    id: 2,
    Country: "US",
    City: "CHICAGO",
    depot_name: "Placeholder",
    depot_code: "USCHIDCCI",
    Size: "40",
    Type: "HC",
    Cndtn: "AS IS",
    QtyInbound: "25",
    QtyOnHandTotal: "0",
    QtyOnHandAvailable: "0",
    QtyBooked: "0",
    OverBooked: "N",
  });

  items.push({
    id: 2,
    Country: "US",
    City: "Kansas City",
    depot_name: "SELECT FLEET DEDICATED",
    depot_code: "USKCKDSFD",
    Size: "40",
    Type: "HC",
    Cndtn: "AS IS",
    QtyInbound: "25",
    QtyOnHandTotal: "2",
    QtyOnHandAvailable: "2",
    QtyBooked: "0",
    OverBooked: "N",
  });

  // for (let index = 0; index < 10; index++) {
  //   items.push({
  //     id: index + 1,
  //     Country: "US",
  //     City: "MEMPHIS",
  //     depot_name: "Memphis IMC",
  //     depot_code: "USMEMDINC",
  //     Size: "40",
  //     Type: "HC",
  //     Cndtn: "AS IS",
  //     QtyInbound: "50",
  //     QtyOnHandTotal: "40",
  //     QtyOnHandAvailable: "20",
  //     QtyBooked: "0",
  //     OverBooked: "N",
  //   });
  // }
  return items;
};

export const HEADER_CUSTOMERSQTY = [
  { text: "Customer name", value: "coName" },
  { text: "Booked Qty", value: "bookedQty" },
];

export const CUSTOMERSQTY = () => {
  let items = [];
  for (let index = 0; index < 5; index++) {
    items.push({
      id: index + 1,
      QtyBooked: "5",
      Customer_name: `Customer ${index}`,
    });
  }
  items.push({
    id: items.length,
    QtyBooked: "25",
    Customer_name: `Total Booked`,
  });
  return items;
};

export const HEADER_DEPOTQTY = [
  {
    text: "Customer",
    value: "customerOrganizationId",

    type: "dropdown",
  },
  // {
  //   text: "Effective From",
  //   value: "effectiveFrom",
  //   width: "45%",
  //   type: "datepicker",
  // },
  { text: "", value: "actions" },
];

export const DEPOTQTY = () => {
  let items = [];
  for (let index = 0; index < 5; index++) {
    items.push({
      id: index + 1,
      Effective: "02/03/2022",
      Customer_name: `Customer ${index}`,
      Active: "N",
    });
  }

  return items;
};
