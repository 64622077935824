export const header = [
  { title: "Code", value: "Code" },
  { title: "Parent Status", value: "step_descr" },
  { title: "Description", value: "substep_descr" },
  { title: "Mode", value: "Mode" },
  // { title: "Interpretation", value: "Interpretation" },
  { title: "Sequence", value: "SequenceNo" },
  // { title: "Step ID", value: "step_id" },
  // { title: "Other", value: "Other" },
  { title: "", value: "Action" },
];

export const containerStatusData = () => {
  let items = [];
  for (let index = 0; index < 49; index++) {
    items.push({
      id: index + 1,
      Code: "",
      substep_descr: `Container Status Description`,
      Mode: "Depot",
      Interpretation: "Container in Yard",
      step_id: index + 1,
      SequenceNo: index + 1,
      Other: "Other description",
    });
  }
  return items;
};

export const containerStatusFormDataRows = [
  {
    title: "Container Status",
    value: "containerStatus",
    fields: [
      { title: "* Code", value: "Code", rules: "required" },
      {
        title: "* Description",
        value: "substep_descr",
        rules: "required",
      },
      {
        title: "Mode",
        value: "Mode",
      },
      // {
      //   title: "Interpretation",
      //   value: "Interpretation",
      // },
      {
        title: "* Parent Status",
        value: "step_id",
        rules: "required",
      },
      {
        title: "Sequence Number",
        value: "SequenceNo",
      },
      // {
      //   title: "Other",
      //   value: "Other",
      // },
    ],
  },
];
