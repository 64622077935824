import {
  create_api_tb_vendor_inventory,
  update_api_tb_vendor_inventory,
} from "@/helpers/api/indexv2.js";

import { DateTime } from "luxon";

export const validationsItem = (item) => {
  try {
    if (item.DateAllocated !== "" && item.DateAllocated !== null) {
      item.DateAllocated = DateTime.fromISO(
        new Date(item.DateAllocated).toISOString(),
      ).toFormat("yyyy-LL-dd");
    }
    if (item.DateAvailable !== "" && item.DateAvailable !== null) {
      item.DateAvailable = DateTime.fromISO(
        new Date(item.DateAvailable).toISOString(),
      ).toFormat("yyyy-LL-dd");
    }
    if (item.EstDateOfReturn !== "" && item.EstDateOfReturn !== null) {
      item.EstDateOfReturn = DateTime.fromISO(
        new Date(item.EstDateOfReturn).toISOString(),
      ).toFormat("yyyy-LL-dd");
    }
    if (item.InboundStatusDate !== "" && item.InboundStatusDate !== null) {
      item.InboundStatusDate = DateTime.fromISO(
        new Date(item.InboundStatusDate).toISOString(),
      ).toFormat("yyyy-LL-dd");
    }
  } catch (error) {
    console.log("validationsItem error", error);
    return false;
  }

  if (item.Price !== "" && item.Price !== null) {
    item.Price = item.Price.replaceAll(",", ".");

    if (!_.isNumber(_.toNumber(item.Price))) {
      return false;
    }
  }
  return item;
};

export const updateItem = async (old_value, new_value) => {
  try {
    let data = {
      id: new_value.id,
      data: new_value,
    };

    data.BuyerParty = null;
    data.BuyerPartyId = null;
    data.allocated_to_entity = null;

    let response = await update_api_tb_vendor_inventory(data);
    return response.message;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const createItem = async (item, index) => {
  try {
    let response = await create_api_tb_vendor_inventory(item);
    if (response.pass === false) {
      console.log("error", response.message);
      return response.message;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};
